import SeoHead from '/utils/bus/seo-head'
import Spm from '/utils/common/spm'
import productUrl from '/utils/common/productUrl'
import toImgUrl from '/utils/common/toImgUrl'

const seoHead = new SeoHead();
const spm = new Spm();

export default (ctx, inject) => {
    inject('ctxApi', {
        seoHead,
        spm,
        productUrl,
        toImgUrl
    })
}