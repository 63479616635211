/**
 * @author 小熊
 * @description directives 自定义指令文件
*/
import Vue from 'vue'

/* spm */
Vue.directive('spm', {
    inserted(el, binding, vnode) {
        el.addEventListener('click', () => {
            vnode.context.$ctxApi.spm.initial(binding.value, vnode.context)
            binding?.value?.on && vnode.context[binding.value.on](binding.value, vnode)
        })
    }
})
/* scroll */
Vue.directive('eleScroll', {
    inserted(el, binding) {
        const f = function(evt) {
            if (binding.value && typeof binding.value === 'function') {
                const rect = el.getBoundingClientRect()
                const {y} = rect
                const innerHeight = window.innerHeight
                const isIntersecting = 0 < y && y < innerHeight
                const isLessInnerHeight = y < innerHeight
                binding.value({
                    rect,
                    innerHeight,
                    isIntersecting,
                    isLessInnerHeight
                }, el);
            }
        };
        el.__useScrollHandler__ = f;
        window.addEventListener('scroll', f);
    },
    unbind(el) {
        window.removeEventListener('scroll', el.__useScrollHandler__);
        delete el.__useScrollHandler__;
    }
})