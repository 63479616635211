/**
 * @description vant 按需引入
 *
*/
import Vue from 'vue'
import {
    Button,
    Image,
    Swipe,
    SwipeItem,
    Grid,
    GridItem,
    Cell,
    Popup,
    Tabbar,
    TabbarItem,
    Sticky,
    Badge,
    NavBar,
    Empty,
    Row,
    Col,
    CellGroup,
    RadioGroup,
    Tab,
    Tabs,
    Field,
    DropdownMenu,
    DropdownItem,
    Checkbox,
    CheckboxGroup,
    Skeleton,
    Sidebar,
    SidebarItem,
    Search,
    NoticeBar,
    Rate,
    Stepper,
    ImagePreview,
    Toast,
    Dialog,
    Lazyload,
    Icon,
    Progress,
    CountDown,
    Collapse,
    CollapseItem,
    Pagination,
    Radio,
    Slider,
    SwipeCell,
    Tag,
    Form,
    Popover,
    Switch,
    IndexAnchor,
    IndexBar,
    Divider,
    DatetimePicker,
    Picker,
    Uploader,
    Loading
} from 'vant'

[
    Button,
    Image,
    Swipe,
    SwipeItem,
    Grid,
    GridItem,
    Cell,
    Popup,
    Tabbar,
    TabbarItem,
    Sticky,
    Badge,
    NavBar,
    Empty,
    Row,
    Col,
    CellGroup,
    RadioGroup,
    Tab,
    Tabs,
    Field,
    DropdownMenu,
    DropdownItem,
    Checkbox,
    CheckboxGroup,
    Skeleton,
    Sidebar,
    SidebarItem,
    Search,
    NoticeBar,
    Rate,
    Stepper,
    Icon,
    Progress,
    CountDown,
    Collapse,
    CollapseItem,
    Pagination,
    Radio,
    Slider,
    SwipeCell,
    Tag,
    Form,
    Popover,
    Switch,
    IndexAnchor,
    IndexBar,
    Divider,
    DatetimePicker,
    Picker,
    Uploader,
    Dialog,
    Loading
].forEach(c => {
    Vue.use(c)
})

Vue.use(Lazyload, {
    lazyComponent: true
})

Vue.prototype.$ImagePreview = ImagePreview;
Vue.prototype.$Toast = Toast;
Vue.prototype.$Dialog = Dialog;
