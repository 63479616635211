/**
 * @author 小熊
 * @description 全局方法文件
*/
import Vue from 'vue';
import Loading from '/components/Loading'

// Vue.prototype.$storage = new Storage()
/*
* @description:         localStorage 本地数据存/取
* @use:                 this.$storage[方法名]
* @options ->           方法名、参数说明具体查看 storage.js 文件
*/

/*
* @description:         多语言翻译
* @use:                 this.lang(参数)
* @options ->           方法名、参数说明具体查看 language.js 文件
*/

let instance = null
const loading = {
    /*
    * @description:         loading加载
    * @use:                 this.$loading()
    * @options -> true、false   是否显示loading加载，默认 true
    * @options -> visible   是否显示loading加载，默认 true
    * @options -> type      loading显示类型，1 普通模式 2 安全模式，默认 1
    * @options -> text      loading加载文案
    */
    install() {
        if(!instance) {
            const ExtendLoading = Vue.extend(Loading)
            instance = new ExtendLoading({
                el: process.client && document.createElement('div')
            })
            process.client && document.body.appendChild(instance.$el)
        }
        instance.visible = false

        const customMethods = (options) => {
            options ?? (options = true)
            if(Object.prototype.toString.call(options).includes('Boolean')) {
                instance.visible = options
            } else {
                instance.visible = options?.visible ?? true
                instance.text = options?.text
            }
            instance.options = {
                visible: options?.visible ?? true,
                text: options?.text,
                type: options?.type ?? 1
            }
        };
        const loadingOn = (key) => {
            return instance.loadingOn(key)
        };

        if(!Vue.$loading) {
            Vue.$loading = customMethods
            Vue.prototype.$loading = Vue.$loading
            Vue.$loadingOn = loadingOn
            Vue.prototype.$loadingOn = Vue.$loadingOn
        }
    }
}

Vue.use(loading)