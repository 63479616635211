import Vue from 'vue'
import {
    vElementVisibility,
    vIntersectionObserver,
} from '@vueuse/components'

import {
    useNetwork,
    useWindowScroll
} from '@vueuse/core'

[
    {key: 'element-visibility', initial: vElementVisibility},
    {key: 'intersection-observer', initial: vIntersectionObserver},
].forEach(c => {
    Vue.directive(c.key, c.initial);
});

export default (ctx, inject) => {
    inject('vueUse', {
        useNetwork,
        useWindowScroll
    })
}